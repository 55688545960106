//import store from './store'
export default [
  {
    name: "APP_FCM_SEND",
    callback: function (params) {
        //return
        let fcmkey =process.env.VUE_APP_FCM_KEY        
        let sendURL =process.env.VUE_APP_FCM_URL
       
        this.$http({
          method: 'post',
          url: sendURL,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `key=${fcmkey}`,
          },
          data: params.playload
        }).then(() => {
          
          this.$toasted.show(params.success, {type: 'success'})
          
        }).catch(() => {
         
          this.$toasted.show(params.error, {type: 'error'})
        })

    }
  },
  {
    name: "APP_IP_ADDRESS",
    callback: function () {
        //return
        //axios.get('http://ipinfo.io/json').then(response => {
        //  console.log('axios',response)
        // commit('setIpAddress', response)
          
        //})
        
        //let fcmkey =process.env.VUE_APP_FCM_KEY        
        //let sendURL =process.env.VUE_APP_FCM_URL
       
        this.$http({
          method: 'get',
          url:'https://cors-anywhere.herokuapp.com/https://media.daum.net/proxy/api/mc2/contents/find.json?contentsType=news,harmony&pageSize=20&page=1&clusterId=238831&range=31&maxDate=20200131',// 'https://www.cloudflare.com/cdn-cgi/trace',//'http://ipinfo.io/json',
          headers: {
            'Content-Type': 'application/json'           
          }
        }).then(() => {
          //console.log('axios',response)
          //this.$toasted.show(params.success, {type: 'success'})
          
        }).catch(() => {
          //console.log('axios e',e)
          //this.$toasted.show(params.error, {type: 'error'})
        })

    }
  },
  {
    name: "APP_OVERLAY",
    callback: function (params) {
      //{text:'내용',color:'success'}
      //console.log('APP_LOGOUT accounts event.js',params)
      //this.onShowSnackbar(params)
      this.overlay = {
        show: params.show,
        text:params.text
       
      }
    }
  },
  
  {
    name: "APP_MARKET_SITE_CHANGE",
    callback: function (params) {

      //{text:'내용',color:'success'}
      //console.log('current this.siteList',this.siteList)
      //console.log('APP_MARKET_SITE_CHANGE  event.js',params)
      //this.onShowSnackbar(params)
      this.siteList = params
      //console.log('this.siteList',this.siteList)
    }
  },
  
  
  {
    name: "APP_TOAST",
    callback: function (params) {
      //{text:'내용',color:'success'}
      //console.log('APP_LOGOUT accounts event.js',params)
      //this.onShowSnackbar(params)
      this.snackbar = {
        show: true,
        color:params.color,
        text: params.text
      }
    }
  },
  {
    name: "APP_LOGIN_SUCCESS",
    callback: () => {
      this.$router.push({ path: "dashboard" })
    }
  },
  {
    name: "APP_LOGOUT",
    callback: function (params) {
      
      //console.log('APP_LOGOUT accounts event.js',params)
      //this.onShowSnackbar(params)
      this.snackbar = {
        show: true,
        color: "success",
        text: params.text
      }
    }
  },
  {
    name: "APP_PAGE_LOADED",
    callback: () => {}
  },
  {
    name: "APP_AUTH_FAILED",
    callback: () => {
      this.$router.push("/login")
      this.$message.error("Token has expired")
    }
  },
  {
    name: "APP_BAD_REQUEST",
    callback: msg => {
      this.$message.error(msg)
    }
  },
  {
    name: "APP_ACCESS_DENIED",
    callback: msg => {
      this.$message.error(msg)
      this.$router.push("/forbidden")
    }
  },
  {
    name: "APP_RESOURCE_DELETED",
    callback: msg => {
      this.$message.success(msg)
    }
  },
  {
    name: "APP_RESOURCE_UPDATED",
    callback: msg => {
      this.$message.success(msg)
    }
  },
  {
    name: "APPLICATION_EXECUTE_START",
    callback: function (params) {

      //{text:'내용',color:'success'}
      //console.log('current this.siteList',this.siteList)
      //console.log('APP_MARKET_SITE_CHANGE  event.js',params)
      //this.onShowSnackbar(params)
      //this.siteList = params
     // console.log('APPLICATION_EXECUTE_SCRAP',params)
      //this.items.unshift(item);
      window.pywebview.api.onExecuteStart(params)
    }
  },
]
