const app = {
  state: {
    user: null,
    isProgram: true,
    isLocalDB: true,
    carOptions: {
      C_CODE: '',
      C_TYPE: '',
      G_CODE: '',
      G_NAME: '',
      L_CODE: '',
      M_AMT: '',
      M_CODE: '',
      OPTIONS: []
    },
    carPrice: {
      defaultPrice: 0,// 차량기본가격
      optionPrice: 0, //옵션가격
      optionCount: 0, //옵션개수
      totalPrice: 0, //최종가격

    },
    modealInfo: {
      options: [],
      info: {}
    },
    danawa: {
      brand: {},
      model: {},
      lineup: {},
      trim: {}
    },
    modeal: {
      brand: {},
      model: {},
      lineup: {},
      trim: {}
    }
  },
  mutations: {
    setCarPriceOption: (state, payload) => {
      state.carPrice.optionPrice = payload.optionPrice
      state.carPrice.optionCount = payload.optionCount
      state.carPrice.totalPrice = state.carPrice.defaultPrice + payload.optionPrice

    },
    setModealInfo: (state, payload) => {
      state.modealInfo = payload


    },
    setCarOptions: (state, payload) => {
      state.carOptions = payload
      state.carPrice.defaultPrice = parseInt(payload.M_AMT)
      state.carPrice.optionPrice = 0
      state.carPrice.optionCount = 0
      state.carPrice.totalPrice = parseInt(payload.M_AMT)

    },
    setUser: (state, payload) => {
      state.user = payload
    },
    setProgram: (state, payload) => {
      state.isProgram = payload
      if (state.isProgram === false) {
        // 프로그램접속이 아니면 무조건 isLocalDB=false 이다(웹에서 접속했으므로)
        state.isLocalDB = payload
      }
    },
    setLocalDB: (state, payload) => {
      state.isLocalDB = payload
    },
    setDanawaBrand: (state, payload) => {
      state.danawa.brand = payload
    },
    setDanawaModel: (state, payload) => {
      state.danawa.model = payload
    },
    setDanawaLineup: (state, payload) => {
      state.danawa.lineup = payload
    },
    setDanawaTrim: (state, payload) => {
      state.danawa.trim = payload
    },

    setModealBrand: (state, payload) => {
      state.modeal.brand = payload
    },
    setModealModel: (state, payload) => {
      state.modeal.model = payload
    },
    setModealLineup: (state, payload) => {
      state.modeal.lineup = payload
    },
    setModealTrim: (state, payload) => {
      state.modeal.trim = payload
    },

  },
  actions: {
    danawaBrand({ commit }, payload) {
      commit('setDanawaBrand', payload) 
    },
    danawaModel({ commit }, payload) {
      commit('setDanawaModel', payload) 
    },
    danawaLineup({ commit }, payload) {
      commit('setDanawaLineup', payload) 
    },
    danawaTrim({ commit }, payload) {
      commit('setDanawaTrim', payload) 
    },
    modealBrand({ commit }, payload) {
      commit('setModealBrand', payload) 
    },
    modealModel({ commit }, payload) {
      commit('setModealModel', payload) 
    },
    modealLineup({ commit }, payload) {
      commit('setModealLineup', payload) 
    },
    modealTrim({ commit }, payload) {
      commit('setModealTrim', payload) 
    },

    modealInfo({ commit }, payload) {
      commit('setModealInfo', payload) 
    },
    carPriceOption({ commit }, payload) {
      commit('setCarPriceOption', payload) 
    },
    carOptions({ commit }, payload) {
      commit('setCarOptions', payload) 
    },
    isProgram ({ commit }, payload) {
      commit('setProgram', payload) //true/false 프로그램접속여부 isProgram=false이면 무조건 isLocalDB=false 이다(웹에서 접속했으므로)
    },
    localDBMode ({ commit }, payload) {
      commit('setLocalDB', payload) //true/false 로컬데이터사용여부
    },
    login ({ commit }, payload) {
      commit('setUser', payload)
    },
    logout ({ commit }) {
      // console.log('store logout')
      commit('setUser', null)
    }
  }
}

export default app
