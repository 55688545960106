import firebase from './../../database/firebase'
const database = {
  state: {
    firebase: firebase,
  },
  actions: {
    firebaseError ( payload) {
      let rv = ''
      switch (payload.errorCode) {
        case 'auth/user-not-found': {
          rv = '이메일 또는 비밀번호가 일치하지 않습니다'
          break
        }
        case 'auth/email-already-in-use': {
          rv = '동일한 이메일이 존재합니다'
          break
        }
        case 'auth/weak-password': {
          rv = '비밀번호는 적어도 6자 이상 입력해야 합니다'
          break
        }
        case 'auth/invalid-email': {
          rv = '이메일주소가 형식에 맞지 않습니다'
          break
        }
        case 'auth/operation-not-allowed': {
          rv = '현재 해당방식의 로그인은 일시중지중입니다'
          break
        }
        case 'auth/internal-error': {
          rv = '인증서버의 예상치 못한 오류입니다'
          break
        }
        case 'auth/invalid-argument': {
          rv = '인증요청에 잘못된 인수가 포함되어 있습니다.'
          break
        }
        default: {
          rv = payload.default
          break
        }
      }
      return rv
    }
  }
}
export default database
