<template>
  <v-app>
    <vue-snotify></vue-snotify> 
    <v-navigation-drawer
      v-model="drawer"
      app
      :mini-variant.sync="miniVariant"
      >
      <v-toolbar flat class="transparent" >
        <v-list-item>
          <v-list-item-icon>
            <v-icon large color="orange">mdi-invert-colors</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >
              <h4 >{{appName}}</h4>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-toolbar>
      <v-divider></v-divider>
      <vue-perfect-scrollbar class="drawer-menu--scroll" >
        <v-list>
          <template v-for="(item,index) in menus">
            <v-divider v-if="item.divider"  :key="`divider-${index}`" ></v-divider>
            <v-subheader v-else-if="item.header" :key="`header-${index}`">
              {{ item.header }} {{$store.getters.user.email}}
            </v-subheader>
            <v-list-group v-else-if="item.isSubItem" :key="index" :prepend-icon="item.icon" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title" ></v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="subItem in item.items"
                :key="subItem.title"
                :to="{name: subItem.name}"
                >
                <v-list-item-content>
                  <v-list-item-title v-text="subItem.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item
              v-else-if="parentMemu(item)"
              :key="`menu-${index}`"
              :to="{name: item.name}"
              >
              <v-list-item-action  >
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="item.isDivider"  :key="`divider-${index}`"  ></v-divider>
          </template>
        </v-list >
      </vue-perfect-scrollbar>
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-tooltip bottom v-if="$route.meta.parent">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" :to="{ name: $route.meta.parent.link }"><v-icon >mdi-chevron-left</v-icon></v-btn>
        </template>
        <span v-text="$route.meta.parent.tooltip"></span>
      </v-tooltip>
      <v-toolbar-title v-text="$route.meta.title"></v-toolbar-title>
      <v-spacer></v-spacer>
      <UserMenu/>
    </v-app-bar>
    <v-main>
      <v-container
        fluid
      >
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer fixed app>
            <main-footer />
        </v-footer>
  </v-app>
</template>

<script>
import menu from '@/api/Menu'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import UserMenu from '@/components/Nav/UserMenu'
import MainFooter from './Footer';
export default {
  name: 'App',
  components: {
    VuePerfectScrollbar,
    UserMenu,
    MainFooter
  },
  data () {
    return {
      appName:process.env.VUE_APP_ADMIN_NAME,
      appVer:process.env.VUE_APP_APP_VER,
      miniVariant: false,
      drawer: null,
      scrollSettings: {
        maxScrollbarLength: 160
      },
      menus: menu,
      query: {
        firebase: this.$store.getters.firebase,
        ref: this.$store.getters.ref,
        user: this.$store.getters.user,
        currentPage: 1,
        pageSize: 10
      }
    }
  },
  created() {},
  methods: {
    parentMemu(item) {
        if (this.$store.getters.user.email==='demo@demo.com') {
            if (item.meta) {
                if (item.meta.email === this.$store.getters.user.email) {
                return true
                } else {
                return false
                }
            } else {
                return false
            }
        } else {
            return true
        }
    }
  }
}
</script>
