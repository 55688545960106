const Menu = [
    {		
		header: "사용자메뉴",
		
	},
	{		
		name: "user.home",
		title: '대시보드',
		icon: "mdi-view-dashboard-edit",
		type: "sub_menu",
		children: null,
        meta: {
            email: 'demo@demo.com'
        }
	},
	{
		name: 'user.modeal.index',
		title: "모두가딜러",
		icon: "mdi-file-chart",
		type: "sub_menu",
		children: null,
        meta: {
            email: 'demo@demo.com'
        }
	},
	/*
    {
		name: 'user.demo.index',
		title: "견적서데모",
		icon: "mdi-file-chart",
		type: "sub_menu",
		children: null,
        meta: {
            email: 'demo@demo.com'
        }
	},
    */
    {
		name: 'user.excel.index',
		title: "엑셀파일업로드",
		icon: "mdi-cloud-upload-outline",
		type: "sub_menu",
		children: null,
        meta: {
            email: 'demo@demo.com'
        }
	},
	{
		name: 'user.car.index',
		title: "차량정보",
		icon: "mdi-car-key",
		type: "sub_menu",
		children: null,
        meta: {
            email: 'demo@demo.com'
        }
	},
    
	{		
		name: "user.history.index",
		title: '4개사이트 정보',
		icon: "mdi-history",
		type: "sub_menu",
		children: null,
	},
    {		
		name: "user.request.index",
		title: '작업요청/문의',
		icon: "mdi-chat-processing",
		type: "sub_menu",
		children: null,
	},
	{		
		name: "user.timeline.index",
		title: '알려드립니다',
		icon: "mdi-account-question",
		type: "sub_menu",
		children: null,
	},
   
	
	
]
export default Menu
