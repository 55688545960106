import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import './plugins/element.js'
import './plugins/index'
import store from './store'
import router from './router'
import firebase from './database/firebase'



Vue.config.productionTip = false
Vue.prototype.$vueEventBus = new Vue()
const unsubscribe = firebase.auth()
.onAuthStateChanged((firebaseUser) => {
  new Vue({
    vuetify,
    store,
    router,
    // axios,
    render: h => h(App),
    created () {
      if (firebaseUser) {
        store.dispatch('login', firebaseUser)
      } else {
        this.$router.push('/auth/login')
      }
    }
  }).$mount('#app')
  unsubscribe()
})