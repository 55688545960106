<template>
<span></span>
</template>

<script>
// import {ipcRenderer} from 'electron'
export default {  
  props: {
    fixed: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    startServer () {
      // ipcRenderer.send('start') 
      this.$log.warn('server start~ vue')
      window.getApp.$emit('APP_MEDIA_START');
      // alert('check localhost:3001')
    }   
  }
};
</script>
<style>
</style>