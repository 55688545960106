import Vue from 'vue';
import Router from 'vue-router';
import paths from './paths';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

Vue.use(Router);
const router =  new Router({
  base: '/',
  mode: 'history',//url에 # 없애는것
  linkActiveClass: 'active',
  routes: paths
  
});
// router gards
router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach(() => {
  // ...
  NProgress.done();
 // this.$log.warn(to,from)
});

export default router;
