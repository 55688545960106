const getters = {
    firebase: state => state.database.firebase,
    ref: state => state.database.ref,
    blog: state => state.database.blog,
    user: state => state.app.user,
    carOptions: state => state.app.carOptions,
    carPrice: state => state.app.carPrice,
    danawa: state => state.app.danawa,
    modeal: state => state.app.modeal,
  }
  export default getters
  