<template>
  <div id="appRoot">
     
    <template v-if="$route.meta.public">
      <public-layouts></public-layouts>
    </template>
    <template v-else>
       
      <user-layouts></user-layouts>
      
    </template>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <v-btn dark  @click.native="snackbar.show = false" icon> 
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar> 
    
   
    
  </div>
</template>
<script>
import AppEvents from  './event'
//import HelloWorld from './components/HelloWorld'
import PublicLayouts from '@/layouts/PublicLayouts'
import UserLayouts from '@/layouts/UserLayouts'

export default {
  name: 'App',
  components: {
    PublicLayouts,
    UserLayouts,
  },
  data () {
    return {
      snackbar: {
        show: false,
        text: '',
        color: '',
      }
    }
  },
  created () {
    // 브라우저 언어셋
    // var userLang = navigator.language || navigator.userLanguage; 
    // this.$log.warn(userLang)
    //this.startServer()
    AppEvents.forEach(item => {
      this.$on(item.name, item.callback);
    });
    window.getApp = this;

    
  },
  methods: {
    
  },
  beforeUpdate () {
   // console.log('beforeUpdate')
   // window.getApp.$emit('SEND_PROGRAM','check');
    
  }
}
</script>
