import AuthGuard from './auth-guard'
export default [
    {
        path: '*',
        meta: {
            public: true,
        },
        redirect: {
            path: '/404'
        }
    },  
    {
        path: '/404',
        meta: {
            public: true,
        },
        name: 'NotFound',
        component: () => import(`@/views/Errors/NotFound.vue`)
    },
    {
        path: '/403',
        meta: {
            public: true,
        },
        name: 'AccessDenied',
        component: () => import(`@/views/Errors/Deny.vue`)
    },
    {
        path: '/500',
        meta: {
            public: true,
        },
        name: 'ServerError',
        component: () => import(`@/views/Errors/Error.vue`)
    },
    {
        path: '/auth/login',
        meta: {
            public: true,
        },
        name: 'auth.login',
        component: () => import(`@/views/Auth/Login.vue`)
    },
    {
        path: '/auth/register',
        meta: {
            public: true,
        },
        name: 'auth.register',
        component: () => import(`@/views/Auth/Register.vue`)
    },
    {
        path: '/',
        meta: {
            public: false,
            title: '대시보드'
        },
        name: 'user.index',
        component: () => import(`@/views/Users/Home/Dashboard.vue`),
        beforeEnter: AuthGuard
    },
    {
        path: '/home',
        meta: {
            public: false,
            title: '대시보드'
        },
        name: 'user.home',
        component: () => import(`@/views/Users/Home/Dashboard.vue`),
        beforeEnter: AuthGuard
    },
    {
        path: '/modeal',
        meta: {
            public: false,
            title: '모두가딜러'
        },
        name: 'user.modeal.index',
        component: () => import(`@/views/Users/Modeal/Index.vue`),
        beforeEnter: AuthGuard
    },
    {
        path: '/match',
        meta: {
            public: false,
            title: '차량매칭'
        },
        name: 'user.modeal.index',
        component: () => import(`@/views/Users/Match/Index.vue`),
        beforeEnter: AuthGuard
    },
    {
        path: '/demo',
        meta: {
            public: false,
            title: '견적서데모'
        },
        name: 'user.demo.index',
        component: () => import(`@/views/Users/Demo/Index.vue`),
        beforeEnter: AuthGuard
    },
    {
        path: '/car',
        meta: {
            public: false,
            title: '차량정보'
        },
        name: 'user.car.index',
        component: () => import(`@/views/Users/Car/Index.vue`),
        beforeEnter: AuthGuard
    },
    {
        path: '/history',
        meta: {
            public: false,
            title: '4개사이트 업데이트 내역'
        },
        name: 'user.history.index',
        component: () => import(`@/views/Users/Update/Index.vue`),
        beforeEnter: AuthGuard
    },
    {
        path: '/excel',
        meta: {
            public: false,
            title: '엑셀파일업로드'
        },
        name: 'user.excel.index',
        component: () => import(`@/views/Users/Excel/Index.vue`),
        beforeEnter: AuthGuard
    },
    {
        path: '/excel/add',
        meta: {
            public: false,
            title: '엑셀견적서업로드-등록'
        },
        name: 'user.excel.add',
        component: () => import(`@/views/Users/Excel/Add.vue`),
        beforeEnter: AuthGuard
    },

    {
        path: '/request',
        meta: {
            public: false,
            title: '작업요청/문의'
        },
        name: 'user.request.index',
        component: () => import(`@/views/Users/Request/Index.vue`),
        beforeEnter: AuthGuard
    },
    
    
    {
        path: '/request/add',
        meta: {
            public: false,
            title: '작업요청-등록'
        },
        name: 'user.request.add',
        component: () => import(`@/views/Users/Request/Add.vue`),
        beforeEnter: AuthGuard
    },
    {
        path: '/blog',
        meta: {
            public: false,
            title: '블로그등록결과'
        },
        name: 'user.blog.index',
        component: () => import(`@/views/Users/Blog/Index.vue`),
        beforeEnter: AuthGuard
    },
    
    {
        path: '/timeline',
        meta: {
            public: false,
            title: '현재까지 개발 진행내역'
        },
        name: 'user.timeline.index',
        component: () => import(`@/views/Users/Timeline/Index.vue`),
        beforeEnter: AuthGuard
    },
    {
        path: '/timeline/add',
        meta: {
            public: false,
            title: '진행내역-등록'
        },
        name: 'user.timeline.add',
        component: () => import(`@/views/Users/Timeline/Add.vue`),
        beforeEnter: AuthGuard
    },
    
    
    {
        path: '/auth/login',
        meta: {
            public: true,
        },
        name: 'auth.login',
        component: () => import(`@/views/App/Login.vue`)
    },
    

]
