import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/messaging'
import 'firebase/analytics'
import 'firebase/firebase-database'

const firebaseConfig = {
  apiKey: "AIzaSyDPir-G7WVQpS5mY54gu0w0Vk5vxKJtuK4",
  authDomain: "fir-fe427.firebaseapp.com",
  projectId: "fir-fe427",
  storageBucket: "fir-fe427.appspot.com",
  messagingSenderId: "385947835600",
  appId: "1:385947835600:web:415aeb26b32ff73f5792d5",
  measurementId: "G-GHDY7504RH"

};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();


export default firebase
